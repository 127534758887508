import '../scss/style.scss';
import { tns } from "tiny-slider/src/tiny-slider";
var Sticky = require('sticky-js');

document.addEventListener('DOMContentLoaded', function () {
    
    var sticky2 = new Sticky('.parent-content');

    const openSubMobiles = document.querySelectorAll('.open-sub-mobile');
        
        openSubMobiles.forEach(function (openSubMobile) {
            openSubMobile.addEventListener('click', function () {
                const parentSub = openSubMobile.closest('.parent-sub');
                const wrapperSupMobile = parentSub.nextElementSibling;

                if (wrapperSupMobile.classList.contains('active')) {
                    wrapperSupMobile.classList.remove('active');
                } else {
                    const allWrappers = document.querySelectorAll('.wrapper-sup-mobile');
                    allWrappers.forEach(function (wrapper) {
                        wrapper.classList.remove('active');
                    });
                    wrapperSupMobile.classList.add('active');
                }
            });
        });

    if (document.querySelector('.parent-content')) {
        setTimeout(function() {
            var heightF = document.querySelector('.parent-content').offsetHeight;
            document.querySelector('.slider-home').style.height = heightF + 'px';
        }, 300);
    }

    if (document.querySelector('#slider-home')) {
        var slidert3 = tns({
            container: '#slider-home',
            mouseDrag: true,
            loop: true,
            items: 1,
            navPos: 'bottom',
            edgePadding: 10,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            slideBy: 1,
            speed: 400,
            autoplay: false,
            prevButton: '.tns-prev',
            nextButton: '.tns-next',
            responsive: {
                0: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1200: {
                    items: 1,
                }
            },
        });
    }

    if (document.querySelector('#slider-cards')) {
        var slidert3 = tns({
            container: '#slider-cards',
            mouseDrag: true,
            loop: true,
            items: 3,
            navPos: 'bottom',
            edgePadding: 10,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            slideBy: 1,
            speed: 400,
            autoplay: false,
            prevButton: '.tns-prev2',
            nextButton: '.tns-next2',
            responsive: {
                0: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1024: {
                    items: 2,
                },
                1200: {
                    items: 3,
                }
            },
        });
    }

    if (document.querySelector('.slider-top')) {
        var slidert3 = tns({
            container: '.slider-top',
            mouseDrag: true,
            loop: true,
            items: 1,
            navPos: 'bottom',
            edgePadding: 0,
            gutter: 0,
            slideBy: 'page',
            swipeAngle: false,
            slideBy: 1,
            speed: 400,
            autoplay: false,
            prevButton: '.tns-prev3',
            nextButton: '.tns-next3',
            responsive: {
                0: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1200: {
                    items: 1,
                }
            },
        });
    }

    if (document.querySelector('.sub-menu')) {
        const subMenu = document.querySelector('.sub-menu');

        function handleScroll() {
            const scrollPosition = window.scrollY;

            if (scrollPosition > 100) {
                subMenu.classList.remove('fadeOut');
                subMenu.classList.add('fadeIn', 'inline-flex');
            } else {
                subMenu.classList.remove('fadeIn', 'inline-flex');
                subMenu.classList.add('fadeOut');
            }
        }

        window.addEventListener('scroll', handleScroll);
    }

    let form = document.querySelector("#newsletter-form");

    if (!form) return;

    let messageContainer = form.querySelector('.alert');

    form.addEventListener("submit", function(event) {
        event.preventDefault();

        // Limpiar el mensaje
        messageContainer.innerHTML = '';
        messageContainer.className = 'alert';  // Reset to original class

        let formData = new FormData(this);
        formData.append('action', 'save_newsletter');

        let actionURL = form.getAttribute('action');
        fetch(actionURL, {
            method: form.method,
            body: formData,
        })
        .then(response => response.json())
        .then(data => {

            messageContainer.innerHTML = data.data;

            if (data.success) {
                messageContainer.classList.add('success');
                form.reset();
            } else {
                messageContainer.classList.add('error');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    });

    document.querySelector('.open-mobile').addEventListener('click', function() {
        document.querySelector('.nav-main').classList.add('lg:flex');
        document.querySelector('.nav-main').classList.remove('lg:hidden');
        console.log(1);
    });
    document.querySelector('.close-nav').addEventListener('click', function() {
        document.querySelector('.nav-main').classList.add('lg:hidden');
        document.querySelector('.nav-main').classList.remove('lg:flex');
    });

    window.addEventListener('resize', function(e) {
        if (window.innerWidth < 960) {
            let menuItems = document.querySelectorAll('.nav-menu li.menu-item-has-children');
            menuItems.forEach(function(menuItem) {
                menuItem.addEventListener('click', function(e) {
                    console.log('click', menuItem);
                    console.log('this', this);

                    let itemHref = menuItem.querySelector('.parent-sub a').getAttribute('href');
                    if (itemHref !== null && itemHref.trim() === '#') {
                        e.preventDefault();
                    }
                    
                    document.querySelectorAll('.nav-menu li.menu-item-has-children ul')
                        .forEach(function(submenu) {
                            submenu.classList.remove('active');
                        });

                    let submenu = this.querySelector('ul');
                    if (submenu) {
                        submenu.classList.add('active');
                    }
                });
            });
        }
    });

    const smoothScrollLinks = document.querySelectorAll('.smooth-scroll');
    if (!smoothScrollLinks) return;

    console.log('#smoothScrollLinks', smoothScrollLinks);
    smoothScrollLinks.forEach(link => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
        
            const targetId = link.getAttribute('href');
            const targetElement = document.querySelector(targetId);
            console.log(targetElement);
            if (!targetElement) return;
         
            const scrollOffset = 70;
            const finalScrollPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - scrollOffset;

            window.scrollTo({
                top: finalScrollPosition,
                behavior: 'smooth'
            });
        });
    });
});